.tourdialog {
  .tg-dialog-dots > span.tg-dot {
    background: var(--mantine-color-dark-0);
  }
  .tg-dialog-dots > span.tg-dot.tg-dot-active {
    background: var(--mantine-color-PSRYellow-5);
  }
  .tg-dialog-btn {
    cursor: pointer;
    outline: unset;
    border: unset;
  }
  #tg-dialog-next-btn {
    color: black;
    background-color: var(--mantine-color-PSRYellow-5);
    font-weight: 550;
  }
  #tg-dialog-prev-btn {
    background-color: var(--mantine-color-dark-9);
    color: var(--mantine-color-dark-0);

    color: var(--mantine-color-dark-0);
  }
  background-color: var(--mantine-color-dark-5);
  color: var(--mantine-color-dark-0);
}
